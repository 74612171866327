import { RegisterOptions } from 'react-hook-form';
import { IUserGridRadioGroupProps } from 'components/CustomButtons/RadioButtonsGroup/RadioButtonsGroup.type';

export type ValueOptions = {
  minLength?: string | number;
  maxLength?: string | number;
  min?: number;
  max?: number;
  disablePast?: boolean;
};

export interface InputObject<T = unknown> {
  id?: string;
  label: string;
  isPassword?: boolean;
  copy?: boolean;
  inputType: InputTypes;
  fieldName: string;
  description?: string;
  defaultValue?: unknown;
  valueOptions?: ValueOptions;
  options?: Array<SelectOption | IRadioOptionsType>;
  config?: RegisterOptions;
  children?: InputObject[];
  disabled?: boolean;
  placeholder?: string;
  size?: 'small' | 'medium';
  radioSpacing?: number;
  isDirectionRadioRow?: boolean;
  selector?: {
    options: OptionType<string | number>[];
    autoWidth?: boolean;
    multiple?: boolean;
    isLoading?: boolean;
    onScrollEnd?: () => void;
  };
  autoComplete?: {
    options: OptionType<string | number>[];
    multiple?: boolean;
    isLoading?: boolean;
    enableCloseOnSelect?: boolean;
    disableInputProps?: boolean;
    clearOnSelect?: boolean;
    onEndScroll?: () => void;
    onChangeTextField?: (value: string) => void;
    messageHelper?: string;
    autoWidthPopper?: boolean;
    isTextArea?: boolean;
  };
  clearDateButton?: boolean;
  useGridLayoutRadio?: IUserGridRadioGroupProps;
  customOnChange?: (value: T) => void;
  useTooltip?: boolean;
}

export interface SelectOption {
  label: string;
  value: string | number;
}
export type IRadioOptionsType = SelectOption & { disabled?: boolean };

export enum InputTypes {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  RADIO = 'radio',
  MULTI_RADIO = 'multi-radio',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  FILE = 'file',
  PHONE_INPUT = 'phone-input',
  DATE = 'date',
  DATETIME = 'dateTime',
  SELECT = 'select',
  AUTOCOMPLETE = 'autocomplete',
  PRICE = 'price',
}

export enum InputSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export type OptionType<T = unknown> = {
  label: string;
  value: T;
};
export type OptionTypeDetails<T = unknown, D = unknown> = {
  option: OptionType<T>;
  details: D;
};
