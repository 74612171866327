export const ENDPOINTS = {
  // articles
  ARTICLES: {
    GET: '/articles',
    GET_BY_ID: (id: string) => `/articles/${id}`,
    GET_PACKING_UNITS: '/packing-units',
    POST: '/articles',
    PATCH: (id: number) => `/articles/${id}`,
    POST_MEDIA: (id: number) => `/articles/${id}/media`,
    DELETE_MEDIA: (id: number) => `/articles/${id}/media/delete`,
    GET_ARTICLE_PCB: (id: number) => `/articles/${id}/pcb`,
    PUT_ARTICLE_PCB: (id: number) => `/articles/${id}/pcb`,
    GET_STOCK: (id: number) => `/articles/${id}/stock`,
    Get_Article_Prices: (id: number) => `/articles/${id}/prices`,
    PUT_Article_Prices: (id: number) => `/articles/${id}/prices`,
    Delete_Article: (id: number) => `/articles/${id}`,
    PUT_MEDIA: (idArticle: number, idMedia: number) => `/articles/${idArticle}/media/${idMedia}`,
    GET_ARTICLE_FROM_SAGE: (id: string) => `/articles/${id}/from-sage`,
  },
  // brands
  BRANDS: {
    GET: '/brands',
    GETBYID: (id: number) => `/brands/${id}`,
  },
  // Publicite
  AD: {
    GET: '/banners',
    GETBYID: (id: number) => `/banners/${id}`,
    ADD_MEDIA: (id_ad: number) => `/banners/${id_ad}/media`,
  },
  // NOTIFICATIONS
  NOTIFICATIONS: {
    GET: '/notifications',
    GETBYID: (id: number) => `/notifications/${id}`,
    ADD_MEDIA: (id_ad: number) => `/notifications/${id_ad}/media`,
  },

  USERS: {
    GET: '/users',
    GETBYID: (id: number) => `/users/${id}`,
  },
  PROFILEACCESS: {
    GET: '/access-profiles',
    GETBYID: (id: number) => `/access-profiles/${id}`,
  },
  ABILITIES: '/abilities',
  // Client classes
  CLIENTCLASS: {
    GET: '/client-classes',
    GETBYID: (id: number) => `/client-classes/${id}`,
  },
  // Client category
  CLIENTCATEGORY: {
    GET: '/client-categories',
    GETBYID: (id: number) => `/client-categories/${id}`,
  },
  // Zone
  ZONE: {
    GET: '/zones',
  },
  // WAREHOUSE
  WAREHOUSE: {
    GET: '/warehouses',
    GETBYID: (id: number) => `/warehouses/${id}`,
  },
  // clients
  CLIENTS: {
    GET: '/clients',
    ADD: '/clients',
    GET_BY_ID: (id_client: string) => `/clients/${id_client}`,
    UPDATE: (id_client: string) => `/clients/${id_client}`,
    DELETE: (id: number) => `/clients/${id}`,
  },
  // Categories
  CATEGORIES: {
    GET: '/catalogs',
    ADD: '/catalogs',
    ADD_MEDIA: (id_category: string) => `/catalogs/${id_category}/media`,
    DELETE: (id_category: string) => `/catalogs/${id_category}`,
    GET_BY_ID: (id_category: number) => `/catalogs/${id_category}`,
    UPDATE: (id_category: number) => `/catalogs/${id_category}`,
    DELETE_MEDIA: (id_category: string) => `/catalogs/${id_category}/media`,
  },
  ZONES: {
    GET: '/zones',
    DELETE: (id_zone: number) => `/zones/${id_zone}`,
    UPDATE: (id_zone: number) => `/zones/${id_zone}`,
    ADD: '/zones',
    GET_BY_ID: (id_zone: string) => `/zones/${id_zone}`,
  },
  SECTORS: {
    DELETE: (id_sector: number) => `/sectors/${id_sector}`,
    UPDATE: (id_sector: number) => `/sectors/${id_sector}`,
    DETAILS: (id_sector: number) => `/sectors/${id_sector}`,
    ADD: '/sectors',
    GET: '/sectors',

    SHIPEMENTSCHEDULE: (warehouseId: number, sectorId: number) =>
      `/warehouses/${warehouseId}/sectors/${sectorId}/shipment-schedule`,
  },
  ORDRES: {
    CREATE: '/orders',
    GET: '/orders',
    SYNCHRONIZE: '/orders/synchronize',
    DETAILS: (id_order: number) => `/orders/${id_order}`,
    DATES: (id_order: number) => `/orders/${id_order}/dates`,
    UPDATE: (id_order: number) => `/orders/${id_order}`,
    LOGS: (id_order: number) => `/orders/${id_order}/logs`,
    CART_LOGS: (idemKey: string) => `/carts/idem-key/${idemKey}/logs`,
    INVOICE: `/orders/invoice`,
  },
  GOVERNORATES: {
    GET: '/governorates',
  },
  // Promo
  PROMO: {
    GENERATE_CODE: '/promo-codes/code/generate',
    CREATE_CODE: '/promo-codes',
    GET: '/promo-codes',
    DELETE: (id: number) => `/promo-codes/${id}`,
    DETAILS: (id: string) => `/promo-codes/${id}`,
    UPDATE: (id: string) => `/promo-codes/${id}`,
  },
  // Deals

  DEALS: {
    GET: '/deals',
    ADD: '/deals',
    ADD_DISCOUNT: '/discounts',
    GET_DISCOUNT: '/discounts',
    UPDATE_DISCOUNT: (id: string) => `/discounts/${id}`,
    GET_DETAILS: (id: string) => `/deals/${id}`,
    UPDATE: (id: string) => `/deals/${id}`,
    DELETE_DISCOUNT: (id: string) => `/discounts/${id}`,
    GET_DISCOUNT_DETAILS: (id: string) => `/discounts/${id}`,
    GET_POSITIONING: '/discounts/positioning',
  },
  // Home sections

  HOME_SECTIONS: {
    GET: '/home/sections',
    ADD: '/home/sections',
    UPDATE: (id: string) => `/home/sections/${id}`,
  },

  // Categories logistics*
  CATEGORIES_LOGISTICS: {
    GET: '/sales-lines',
    GET_DETAILS: (id: number) => `/sales-lines/${id}`,
    DELETE: (id: number) => `/sales-lines/${id}`,
    UPDATE: (id: number) => `/sales-lines/${id}`,
    ADD: '/sales-lines',
  },

  // auth
  LOGIN: '/auth/user/login',
  LOGOUT: '/auth/logout',
  REGISTER: 'register',

  REFRESH_TOKEN: '/auth/refresh-token',
};
